<template>
    <van-config-provider :theme-vars="myTheme">
        <van-cell-group class="shadow-md" inset>
            <div class="image-container"
                :style="{ backgroundImage: 'url(' + Env.classPath + classDetails.cover + ')' }">
                <div class="overlay">
                    <div class="text-center pt-8 title-with-shadow">{{ className }}</div>
                </div>
            </div>

            <van-cell-group>
                <van-cell icon="edit">
                    <template #title>
                        <span class="text-gray-500">{{ classInfo }}</span>
                    </template>
                </van-cell>
                <van-cell :title="lang[langCode].titleAddress" :value="classDetails.classAddress" icon="location-o" />
                <van-cell>
                    <template #title>
                        <div class="flex justify-center flex-wrap">
                            <van-tag class="mb-1 mr-3" color="#6665a3" size="medium"
                                v-for="(info, index) in classDetails.classMetaInfo" :key="index">{{ info }}</van-tag>
                        </div>
                    </template>
                </van-cell>
            </van-cell-group>

            <div class="text-sm pl-4 text-gray-500 pt-8">{{ lang[langCode].titlePlan }}</div>
            <van-cell-group>
                <van-cell icon="calendar-o">
                    <template #title>
                        <span>{{ datesText.text }}</span>
                    </template>
                    <template #value>
                        <span>{{ datesText.hoursText }}</span>
                    </template>
                </van-cell>
                <br />
            </van-cell-group>

            <van-cell :title="lang[langCode].titleTimes" icon="clock-o">
                <template #value>
                    <session-time v-for="(slot, index) in classDetails.classTimes" :key="index" :day="slot.day"
                        :time-start="slot.timeStart" :time-end="slot.timeEnd" :lang-code="langCode"
                        :duration="slot.duration" />
                </template>
            </van-cell>
            <van-cell v-if="showCredit" title="Credit Cost" :value="classDetails.classDates.cost" icon="paid" />
            <van-cell v-if="classDetails.teachers.length" :title="lang[langCode].titleType"
                :value="lang[langCode].typeTeach" icon="point-gift-o" />
            <van-cell v-else :title="lang[langCode].titleType" :value="lang[langCode].typeNotTeach"
                icon="point-gift-o" />
            <br />
            <van-cell v-if="validStudents" :title="lang[langCode].titleStudents" :value="validStudents"
                icon="friends-o" />
        </van-cell-group>
    </van-config-provider>
</template>

<script>
import lang from "@/langs/ClassDetailsCardLang.js";
import Env from "@/logics/Envs.js";
import {
    ref,
    computed
} from "vue";
import {
    Cell,
    CellGroup,
    Tag
} from "vant";
import Helpers from "@/logics/Helpers.js";
import SessionTime from "@/components/text/SessionTime.vue";
import GetDays from "@/logics/GetDaysOfWeekInRange.js";

export default {
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Tag.name]: Tag,
        SessionTime
    },
    props: {
        classDetails: Object,
        langCode: {
            type: String,
            default: "zh"
        },
        myTheme: Object,
        showCredit: Boolean
    },
    setup(props) {
        const datesText = ref(null);
        let totalMins = 0;
        let totalDays = 0
        GetDays(props.classDetails.classTimes, props.classDetails.classDates.start, props.classDetails.classDates.end, function (day, slot) {
            totalMins += slot.duration;
            totalDays += 1;
        });
        const hours = (totalMins / 60).toFixed(1);
        let hoursText = hours + "小时" + "/" + totalDays + "天";
        if (props.langCode !== "zh") {
            hoursText = hours + " hours" + " / " + totalDays + " days";
        }
        datesText.value = {
            hoursText,
            text: Helpers.makeDateRange(props.classDetails.classDates.start, props.classDetails.classDates.end, props.langCode)
        };

        const validStudents = ref(null);
        const showText = function (text) {
            validStudents.value = text;
        };

        const className = computed(() => {
            if (!props.classDetails.langs) {
                return props.classDetails.className;
            }
            if (!props.classDetails.langs[props.langCode]) {
                return props.classDetails.className;
            }
            return props.classDetails.langs[props.langCode].className;
        });

        const classInfo = computed(() => {
            if (!props.classDetails.langs) {
                return "";
            }
            if (!props.classDetails.langs[props.langCode]) {
                return "";
            }
            return props.classDetails.langs[props.langCode].classInfo;
        });

        return {
            Env,
            lang,
            datesText,
            validStudents,
            showText,
            className,
            classInfo
        };
    }
}
</script>

<style scoped>
.title-with-shadow {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: #6665a3;
    text-shadow: rgba(102, 101, 163, 0.5) 0px 3px 3px;
    font-size: 1.5rem;
    font-weight: 900;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100px;
    background: center/cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    pointer-events: none;
}
</style>
