<template>
    <div class="pt-32 flex flex-wrap" v-if="user">
        <div class="md:w-1/3 w-full mb-12">
            <class-details-card v-if="classDetails" :classDetails="classDetails" :lang-code="langCode"
                :my-theme="myTheme" />
        </div>
        <div class="md:w-2/3 w-full flex flex-col items-center px-4 gap-4">
            <van-empty v-if="!classTaskReady.length" description="此课程还没有发布任何任务" />
            <van-cell-group class="shadow-md w-full" inset v-for="(task, index) in classTaskReady" :key="task.tid">
                <van-cell :title="task.name" :label="task.info" is-link @click="clickTask(task, index)">
                    <template #value>
                        <div class="flex flex-row items-center justify-end">
                            <van-icon name="todo-list-o" size="1rem" class="pr-2" />
                            <span>{{ task.dueDate }}</span>
                        </div>
                    </template>
                </van-cell>
                <van-cell v-for="student in task.subsArray" :key="student.sid">
                    <template #title>
                        <van-tag v-if="student.late" type="danger" class="cursor-pointer">{{ student.name }}</van-tag>
                        <van-tag v-else type="primary" class="cursor-pointer">{{ student.name }}</van-tag>
                    </template>
                    <template #label>
                        <van-icon name="play-circle-o" class="pr-1" />
                        <span>{{ student.startText }}</span>
                    </template>
                    <template #value>
                        {{ student.du }} min
                    </template>
                </van-cell>
            </van-cell-group>
            <van-button v-if="!isStudent" class="mt-8 w-1/2" type="primary" @click="showAddTask()">添加新任务</van-button>
        </div>
        <dialog-task ref="refTaskDialog" @insert="addTask" @update="updateTask" @remove="removeTask" />
        <dialog-submission ref="refTaskSub" @add-sub="addSubmission" @remove-sub="removeSubmission" />
    </div>
</template>

<script>
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    CellGroup,
    Button,
    Tag,
    Cell,
    Icon,
    Empty,
    Notify
} from "vant";
import myTheme from "@/logics/MyVantTheme.js";
import ClassDetailsCard from "@/components/ClassDetailsCard.vue";
import GetSession from "@/asyncs/GetSession.js";
import LoadClass from "@/asyncs/LoadClass.js";
import LoadClassTask from "@/asyncs/LoadClassTask.js";
import DialogTask from "@/components/dialog/ClassTaskContent.vue";
import DialogSubmission from "@/components/dialog/ClassTaskSubmit.vue";
import SetTask from "@/asyncs/SetClassTask.js";
import SetSubmission from "@/asyncs/SetTaskSub.js";
import UserTypes from "@/static_values/StaticUserTypes.js";

export default {
    name: "PageClassTask",
    components: {
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [Tag.name]: Tag,
        [Cell.name]: Cell,
        [Icon.name]: Icon,
        [Empty.name]: Empty,
        ClassDetailsCard,
        DialogTask,
        DialogSubmission
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        const route = useRoute();
        const router = useRouter();
        const classDetails = ref(null);
        const classTasks = ref([]);
        const refTaskDialog = ref(null);
        const isStudent = ref(true);
        const refTaskSub = ref(null);
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                isStudent.value = UserTypes.student.value === store.state.user.userType;
                if (!store.state.classDetails) {
                    classDetails.value = await LoadClass(route.params.classId);
                    if (!classDetails.value) {
                        return;
                    }
                } else {
                    classDetails.value = store.state.classDetails;
                }
                store.commit("setPageTitle", classDetails.value.className);

                classTasks.value = await LoadClassTask(route.params.classId);
                if (!classTasks.value) {
                    classTasks.value = [];
                }
            });
        });
        const classTaskReady = computed(function () {
            console.log("classTaskReady");
            const taskList = [];
            if (!classTasks.value) {
                return taskList;
            }
            classTasks.value.forEach(function (t) {
                taskList.push(t);
                if (t.due) {
                    const date = new Date(t.due);
                    t.dueDate = date.toLocaleString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "numeric"
                    });
                }
                if (t.subs) {
                    const students = Object.entries(t.subs).map(function ([sid, sub]) {
                        sub.sid = sid;
                        sub.startText = (new Date(sub.start)).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "numeric"
                        });
                        return sub;
                    });
                    if (isStudent.value) {
                        t.subsArray = students.filter(function (student) {
                            return student.sid === store.state.user.userId;
                        });
                    } else {
                        t.subsArray = students;
                    }
                }
            });
            return taskList;
        });

        const showAddTask = function () {
            refTaskDialog.value.showMe();
        };
        const clickTask = function (task, index) {
            if (isStudent.value) {
                if (!task.open) {
                    if (Date.now() > task.due) {
                        Notify({
                            type: "danger",
                            message: "任务已到期，无法提交个人记录，请联系老师"
                        });
                        return;
                    }
                }
                const sub = task.subsArray.find(function (item) {
                    return item.sid === store.state.user.userId;
                });
                if (sub) {
                    //update
                    refTaskSub.value.showMe(task, store.state.user.profile.showName, sub);
                } else {
                    //add
                    refTaskSub.value.showMe(task, store.state.user.profile.showName);
                }
            } else {
                refTaskDialog.value.showMe(task, index);
            }
        };
        const addTask = async function (task) {
            const newTask = await SetTask(route.params.classId, task);
            if (!newTask) {
                return;
            }
            classTasks.value.push(newTask);
        };
        const updateTask = async function ({
            task,
            index
        }) {
            const taskToUpdate = JSON.parse(JSON.stringify(task));
            delete taskToUpdate.dueDate;
            delete taskToUpdate.subs;
            delete taskToUpdate.subsArray;
            await SetTask(route.params.classId, taskToUpdate);
            classTasks.value.splice(index, 1, task);
        };
        const removeTask = async function ({
            tid,
            index
        }) {
            await SetTask(null, null, tid);
            classTasks.value.splice(index, 1);
        };
        const addSubmission = async function ({
            task,
            sub
        }) {
            const ok = await SetSubmission(task.tid, sub);
            if (!ok) {
                return;
            }
            sub.startText = (new Date(sub.start)).toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "numeric"
            });
            sub.late = Date.now() > task.due;
            task.subs[store.state.user.userId] = sub;
        };
        const removeSubmission = async function (task) {
            const ok = await SetSubmission(task.tid, {}, true);
            if (!ok) {
                return;
            }
            delete task.subs[store.state.user.userId];
        };
        return {
            langCode: computed(() => store.state.lang),
            myTheme,
            classDetails,
            user: computed(() => store.state.user),
            classTasks,
            classTaskReady,
            showAddTask,
            refTaskDialog,
            clickTask,
            addTask,
            updateTask,
            removeTask,
            isStudent,
            refTaskSub,
            addSubmission,
            removeSubmission
        };
    }
};
</script>
