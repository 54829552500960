export default {
    en: {
        titleAddress: "Class Address",
        titlePlan: "Class Schedule",
        titleTimes: "Class Times",
        titleType: "Class Type",
        titleStudents: "Students",
        typeTeach: "Teaching",
        typeNotTeach: "No teaching"
    },
    zh: {
        titleAddress: "地址",
        titlePlan: "课程安排",
        titleTimes: "课程时间",
        titleType: "课程类型",
        titleStudents: "已录取学生",
        typeTeach: "教学课程",
        typeNotTeach: "非教学课程"
    }
}