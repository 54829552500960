export default {
    makeTimeText(time) {
        let hour = Math.floor(time / 100);
        let min = time % 100;
        let half = "AM";
        if (hour >= 12) {
            half = "PM";
            if (hour > 12) {
                hour -= 12;
                if (hour >= 12) {
                    hour = 0;
                    half = "AM";
                }
            }
        }
        let text = "" + hour;
        if (min > 0) {
            text += ":" + min.toString().padStart(2, "0") + half;
        } else {
            text += half;
        }
        return text;
    },
    makeClassTimeToText(time) {
        const hour = Math.trunc(time / 100);
        const min = time % 100;
        return hour.toString().padStart(2, "0") + ":" + min.toString().padStart(2, "0");
    },
    makeDateText(year, month, day) {
        return year + "-" + month.toString().padStart(2, "0") + "-" + day.toString().padStart(2, "0");
    },
    makeDateRange(start, end, userLang) {
        const now = new Date();
        let startMonthText = "";
        let endMonthText = "";
        let startDayText = "";
        let endDayText = "";
        if (userLang === "zh") {
            startMonthText = start.month + "月";
            endMonthText = end.month + "月";
            startDayText = start.day + "日";
            endDayText = end.day + "日";
        } else {
            startMonthText = new String(start.month).padStart(2, "0") + "/";
            endMonthText = new String(end.month).padStart(2, "0") + "/";
            startDayText = new String(start.day).padStart(2, "0");
            endDayText = new String(end.day).padStart(2, "0");
        }
        if (end.year === now.getFullYear() && end.year === start.year) {
            return startMonthText + startDayText + " - " + endMonthText + endDayText;
        }
        let startYearText = "";
        let endYearText = "";
        if (userLang === "zh") {
            startYearText = start.year + "年";
            endYearText = end.year + "年";
        } else {
            startYearText = start.year + "/";
            endYearText = end.year + "/";
        }
        return startYearText + startMonthText + startDayText + " - " + endYearText + endMonthText + endDayText;
    },
    removeClassNotInMonth(classList, year, month) {
        const newList = [];
        classList.forEach(function (c) {
            if (c.classDates.start.year > year) {
                return;
            }
            if (c.classDates.end.year < year) {
                return;
            }
            if (c.classDates.start.year === year) {
                if (c.classDates.start.month > month) {
                    return;
                }
            }
            if (c.classDates.end.year === year) {
                if (c.classDates.end.month < month) {
                    return;
                }
            }
            newList.push(c);
        });
        return newList;
    }
}