import moment from "moment";

export default function (classTimes, myStart, myEnd, gotDay) {
    //month: 1...12
    const start = moment(new Date(myStart.year, myStart.month - 1, myStart.day));
    const end = moment(new Date(myEnd.year, myEnd.month - 1, myEnd.day));

    classTimes.forEach(function (slot) {
        const cursor = moment(new Date(myStart.year, myStart.month - 1, myStart.day));
        cursor.day(slot.day);
        while (cursor.isBefore(start)) {
            //make sure the first cursor is at or after the starting date
            cursor.add(7, "d");
        }
        while (cursor.isBetween(start, end, undefined, "[]")) {
            gotDay([cursor.year(), cursor.month() + 1, cursor.date()], slot);
            cursor.add(7, "d");
        }
    });
}