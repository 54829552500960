<template>
<div class="flex justify-between items-baseline mb-2">
    <span class="text-xs text-gray-500">{{weekDay}}</span>
    <div @click="showDuration = !showDuration" class="cursor-pointer">
        <van-tag color="#6665a3" v-if="more">
            <van-badge dot><span>{{classTimeStart}} - {{classTimeEnd}}</span></van-badge>
        </van-tag>
        <van-tag color="#6665a3" v-else>
            <span>{{classTimeStart}} - {{classTimeEnd}}</span>
        </van-tag>
        <van-tag color="#6665a3" v-if="showDuration && duration" class="ml-1">
            <span>{{ hoursText }}</span>
        </van-tag>
    </div>
</div>
</template>

<script>
import Helpers from "@/logics/Helpers.js";
import StaticDays from "@/static_values/StaticDays.js";
import {
    ref,
    computed
} from "vue";
import {
    Tag,
    Badge
} from "vant";

export default {
    components: {
        [Tag.name]: Tag,
        [Badge.name]: Badge
    },
    props: {
        day: Number,
        timeStart: Number,
        timeEnd: Number,
        more: Boolean,
        langCode: {
            type: String,
            default: "zh"
        },
        duration: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const hours = props.duration / 60;
        const hoursText = ref(hours.toFixed(1) + "h");
        const showDuration = ref(false);

        return {
            weekDay: computed(() => StaticDays[props.day][props.langCode]),
            classTimeStart: computed(() => Helpers.makeTimeText(props.timeStart)),
            classTimeEnd: computed(() => Helpers.makeTimeText(props.timeEnd)),
            hoursText,
            showDuration
        };
    }
}
</script>
