export default {
    1: {
        zh: "周一",
        en: "Mon"
    },
    2: {
        zh: "周二",
        en: "Tue"
    },
    3: {
        zh: "周三",
        en: "Wed"
    },
    4: {
        zh: "周四",
        en: "Thu"
    },
    5: {
        zh: "周五",
        en: "Fri"
    },
    6: {
        zh: "周六",
        en: "Sat"
    },
    0: {
        zh: "周日",
        en: "Sun"
    }
}