<template>
<van-dialog v-model:show="show" title="完成任务" show-cancel-button :confirm-button-text="'提交'" :confirm-button-color="'#ee0a24'" @confirm="confirm()" class="md:w-1/3 w-full">
    <van-divider />
    <div class="overflow-y-scroll my-max-h">
        <van-cell title="任务名称" :value="task.name" />
        <van-cell title="学生姓名" :value="mySub.name" />
        <div class="text-sm text-gray-500 pl-4 pt-4">你什么时候开始此任务？</div>
        <van-datetime-picker v-model="datePick" type="datehour" :show-toolbar="false" title="选择年月日" item-height="30px" visible-item-count="3" :columns-order="['month', 'day', 'year', 'hour']" :formatter="formatter" :min-date="minDate" :max-date="maxDate" class="py-4" />
        <van-cell title="任务执行时长">
            <template #value>
                <van-stepper v-model.number="mySub.du" step="5" integer min="0" max="720" />
                <span class="pl-2">min</span>
            </template>
        </van-cell>
        <van-field label="证明链接 " placeholder="(optional)" v-model="mySub.url" />
        <div class="p-4 text-center" v-if="exist">
            <van-button type="danger" @click="remove()">删除记录</van-button>
        </div>
    </div>
</van-dialog>
</template>

<script>
import {
    ref
} from "vue";
import {
    Dialog,
    Divider,
    Field,
    DatetimePicker,
    Cell,
    Switch,
    Button,
    Stepper
} from "vant";

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider,
        [Field.name]: Field,
        [DatetimePicker.name]: DatetimePicker,
        [Cell.name]: Cell,
        [Switch.name]: Switch,
        [Button.name]: Button,
        [Stepper.name]: Stepper
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const now = new Date();
        const datePick = ref(now);
        const minDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        const maxDate = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
        const allMonth = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        const formatter = function (type, value) {
            if (type === "month") {
                return allMonth[parseInt(value) - 1];
            }
            if (type === "hour") {
                const hour = parseInt(value);
                if (hour > 12) {
                    return (hour % 12).toString().padStart(2, "0") + " PM";
                } else if (hour === 12) {
                    return value + " PM";
                } else {
                    return value + " AM";
                }
            }
            return value;
        };
        const exist = ref(false);
        const mySub = ref({});
        const task = ref(null);
        const showMe = function (taskItem, studentName, sub) {
            task.value = taskItem;
            if (sub) {
                exist.value = true;
                mySub.value = JSON.parse(JSON.stringify(sub));
                datePick.value = new Date(mySub.value.start);
            } else {
                exist.value = false;
                mySub.value = {
                    start: datePick.value.getTime(),
                    du: 0,
                    name: studentName,
                    late: false,
                    url: ""
                }
            }
            show.value = true;
        };
        const confirm = function () {
            mySub.value.start = datePick.value.getTime();
            emit("addSub", {
                task: task.value,
                sub: mySub.value
            });
        };
        const remove = function () {
            emit("removeSub", task.value);
            show.value = false;
        };
        return {
            show,
            showMe,
            datePick,
            formatter,
            minDate,
            maxDate,
            mySub,
            task,
            exist,
            confirm,
            remove
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
