<template>
<van-dialog v-model:show="show" title="添加任务" show-cancel-button :confirm-button-text="'提交'" :confirm-button-color="'#ee0a24'" @confirm="confirm()" :confirm-button-disabled="!task.name" class="md:w-1/3 w-full">
    <van-divider />
    <div class="overflow-y-scroll my-max-h">
        <van-field label="任务名称" placeholder="输入名称" v-model="task.name" />
        <van-field label="任务信息" placeholder="输入信息" v-model="task.info" />
        <div class="text-sm text-gray-500 pl-4 pt-4">任务到期</div>
        <van-datetime-picker v-model="datePick" type="datehour" :show-toolbar="false" title="选择年月日" item-height="30px" visible-item-count="3" :columns-order="['month', 'day', 'year', 'hour']" :formatter="formatter" :min-date="minDate" :max-date="maxDate" class="py-4" />
        <van-divider />
        <van-field label="任务访问码" placeholder="可留空" v-model="task.code" />
        <van-cell title="无限时提交" v-if="index >= 0">
            <template #value>
                <van-switch v-model="task.open" size="20px" active-color="#07c160" />
            </template>
        </van-cell>
        <div class="p-4 text-center" v-if="index >= 0">
            <van-button type="danger" @click="remove()">删除任务</van-button>
        </div>
    </div>
</van-dialog>
</template>

<script>
import {
    ref
} from "vue";
import {
    Dialog,
    Divider,
    Field,
    DatetimePicker,
    Cell,
    Switch,
    Button
} from "vant";

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider,
        [Field.name]: Field,
        [DatetimePicker.name]: DatetimePicker,
        [Cell.name]: Cell,
        [Switch.name]: Switch,
        [Button.name]: Button
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const now = new Date();
        const datePick = ref(now);
        const minDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        const maxDate = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
        const allMonth = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        const formatter = function (type, value) {
            if (type === "month") {
                return allMonth[parseInt(value) - 1];
            }
            if (type === "hour") {
                const hour = parseInt(value);
                if (hour > 12) {
                    return (hour % 12).toString().padStart(2, "0") + " PM";
                } else if (hour === 12) {
                    return value + " PM";
                } else {
                    return value + " AM";
                }
            }
            return value;
        };
        const task = ref({});
        const index = ref(-1);
        const showMe = function (taskToUpdate, taskIndex) {
            index.value = -1;
            if (taskToUpdate) {
                task.value = JSON.parse(JSON.stringify(taskToUpdate));
                datePick.value = new Date(task.value.due);
            } else {
                task.value = {
                    name: "",
                    info: "",
                    code: "",
                    open: false
                };
            }
            if (taskIndex >= 0) {
                index.value = taskIndex;
            }
            show.value = true;
        };
        const confirm = function () {
            task.value.due = datePick.value.getTime();
            if (index.value >= 0) {
                emit("update", {
                    task: task.value,
                    index: index.value
                });
            } else {
                emit("insert", task.value);
            }
        };
        const remove = function () {
            emit("remove", {
                tid: task.value.tid,
                index: index.value
            });
            show.value = false;
        };
        return {
            show,
            showMe,
            datePick,
            formatter,
            minDate,
            maxDate,
            confirm,
            task,
            index,
            remove
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
